import { createSlice } from '@reduxjs/toolkit'

export const initialData = {
    data: {},
    show: false,
    loading: false,
    list: [],    // Liste navigasyonu için gerekli
    index: []    // ID'lerin index listesi
}

const visitDrawerSlice = createSlice({
    name: 'visitDrawer',
    initialState: {...initialData},
    reducers: {
        updateDrawer: (state, action) => {
            state.data = action.payload
        },
        closeDrawer: (state) => {
            state.show = false
        },
        openDrawer: (state) => {
            state.show = true
        },
        updateList: (state, action) => {
            let dir = [];
            action.payload.map((item) => {
                dir.push(item.id)
            })
            state.index = dir
            state.list = action.payload
        }
    }
})

export const { updateDrawer, openDrawer, closeDrawer, updateList } = visitDrawerSlice.actions

export default visitDrawerSlice.reducer